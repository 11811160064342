<template>
    <v-card elevation="6" :min-height="510" class="mt-4 mb-12 px-4 mr-6 overflow-y-auto">
        <v-data-table
            :headers="headers"
            :items="students"
            :loading="loading"
            hide-default-footer
            hide-default-header
        >
            <template v-slot:header="{ props: { headers } }">
                <thead>
                    <tr>
                        <th colspan="7" scope="row">
                            <v-row class="text-caption text-xl-body-2 font-weight-black">
                                <v-col cols="3" class="py-4">{{ headers[0].text }}</v-col>
                                <v-col cols="1" class="py-4">{{ headers[1].text }}</v-col>
                                <v-col cols="1" class="py-4">{{ headers[2].text }}</v-col>
                                <v-col cols="4" class="py-4">{{ headers[3].text }}</v-col>
                                <v-col cols="1" class="py-3 px-0">{{ headers[4].text }}</v-col>
                                <v-col cols="1" class="py-3 px-0">{{ headers[5].text }}</v-col>
                                <v-col cols="1" class="py-3 px-0">{{ headers[6].text }}</v-col>
                            </v-row>
                        </th>
                    </tr>
                </thead>
            </template>
            <template v-slot:body="{ items }">
                <tbody class="text-body-2 font-weight-bold text-gray">
                    <tr
                        v-for="item in items"
                        :key="item.reader.id"
                    >
                        <v-menu
                            v-model="item.show"
                            :close-on-click="true"
                            :close-on-content-click="false"
                            absolute
                            offset-y
                        >
                            <template v-slot:activator="{ on }">
                                <v-row class="mx-0" v-on="on">
                                    <v-col cols="3">
                                        <v-row align="center">
                                            <v-col cols="2" class="py-0">
                                                <v-img
                                                    :src="getAvatarSrc(item.reader.avatar)"
                                                    max-height="30"
                                                    max-width="30"
                                                />
                                            </v-col>
                                            <v-col cols="10" class="py-0 text-capitalize">
                                                {{ item.name | capitalize }}
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="1">{{ item.correctAnswers }}</v-col>
                                    <v-col cols="1">{{ item.totalAnswers }}</v-col>
                                    <v-col cols="4" class="py-1">
                                        <BeeProgressBar
                                            :color="getColorAccuracyBar(item.accuracy)"
                                            class="pa-0 text-center font-size-14"
                                            :progress="Math.round(item.accuracy)"
                                            :backgroundless="true"
                                            :valueColorBlack="textBlack(item.accuracy)"
                                        >
                                            {{ `${Math.round(item.accuracy)}%` }}
                                        </BeeProgressBar>
                                    </v-col>
                                    <v-col cols="1" class="px-0">{{ Math.round(item.accuracyLoc) }}%</v-col>
                                    <v-col cols="1" class="px-0">{{ Math.round(item.accuracyIn) }}%</v-col>
                                    <v-col cols="1" class="px-0">{{ Math.round(item.accuracyRv) }}%</v-col>
                                </v-row>
                            </template>
                            <SessionsLiveTableDetail :studentId="item.reader.id" :sessionId="session" @close="closeDetail()" />
                        </v-menu>
                    </tr>
                    <tr v-show="items.length===0">
                        <th colspan="7" scope="row">
                            <v-row justify="center">
                                {{ $t('sessions.no_data') }}
                            </v-row>
                        </th>
                    </tr>
                </tbody>
            </template>
        </v-data-table>
        <v-overlay :absolute="true" :value="students.length===0 && loading">
            <v-progress-circular
                indeterminate
                color="primary"
                size="100"
                :width="10"
            />
        </v-overlay>
    </v-card>
</template>

<script>
import SessionsLiveTableDetail from './SessionsLiveTableDetail'
import WorkSessionService from '../../services/workSession'

export default {
    props: {
        session: { type: Number, default: 0 },
        tier: { type: Number, default: 0 },
    },
    components: {
        SessionsLiveTableDetail,
    },
    data() {
        return {
            showDescription: false,
            loading: false,
            students: [],
        }
    },
    computed: {
        headers() {
            return [
                { text: this.$t('sessions.students'), align: 'start', value: 'name', sortable: false, },
                { text: this.$t('sessions.correct'), value: 'correctAnswers', sortable: false, },
                { text: 'Total', value: 'totalAnswers', sortable: false, },
                { text: this.$t('sessions.accuracy'), value: 'accuracy', sortable: false, },
                { text: this.$t('sessions.find_locate'), value: 'accuracyLoc', sortable: false, },
                { text: this.$t('sessions.integrate_interpret'), value: 'accuracyIn', sortable: false, },
                { text: this.$t('sessions.reflect_valuate'), value: 'accuracyRv', sortable: false, },
            ]
        },
    },
    mounted() {
        this.loadData()
    },
    methods: {
        closeDetail() {
            this.students = this.students.map((item) => {
                const resp = item
                resp.show = false
                return resp
            })
        },
        getColorAccuracyBar(progress) {
            let result = '#6F55D0'
            if (progress >= 40) {
                result = '#FDDC61'
            }
            if (progress >= 67) {
                result = '#34DBAA'
            }
            return result
        },
        getAvatarSrc(avatar) {
            return `${process.env.VUE_APP_S3RESOURCES}/avatar/${avatar.name}/${avatar.value}`
        },
        textBlack(progress) {
            return progress < 20 || (progress >= 40 && progress < 67)
        },
        mapData(items) {
            if (items.length === 0) {
                this.students = []
                return
            }
            const studentList = items.find((element) => element.tierNumber === this.tier)
            this.students = studentList.students.map((item) => (
                { name: `${item.reader.firstname} ${item.reader.lastname}`, show: false, ...item }
            ))
        },
        loadData() {
            if (this.session === 0) {
                this.students = []
                return
            }
            this.loading = true
            WorkSessionService.getStudentListBySession(this.session)
                .then((response) => {
                    this.loading = false
                    this.mapData(response.content)
                })
                .catch((error) => console.error(error))
        },
    },
    watch: {
        session() {
            this.loadData()
        },
        tier() {
            this.loadData()
        },
    },
}
</script>
<style scoped>
.text-gray {
    color: #8D8D8D;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
    border-bottom: 1px dashed gray;
}
.border-bottom {
    border-bottom: 1px solid white;
}
.v-menu__content {
    box-shadow: none;
}
</style>
