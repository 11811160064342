<template>
    <v-card elevation="12" class="py-6 px-8">
        <v-row>
            <h2 class="text-h4 font-weight-black px-2">
                {{ $t('sessions.sessions_data') }}
            </h2>
        </v-row>
        <v-row>
            <v-col cols="12" lg="3" class="mt-0 mt-lg-6 mx-0 px-0">
                <v-row class="pr-lg-2 pr-xl-0">
                    <BeeTabs
                        @changeTab="changeTab"
                        :items="items"
                        tab-color="#514773"
                        :height="$vuetify.breakpoint.lg?70:40"
                        :vertical="$vuetify.breakpoint.lg"
                        :textAlign="'start'"
                    />
                </v-row>
                <SelectFilters
                    class="mx-0 px-1"
                    :tab="currentTab"
                    v-on:newClass="setClass"
                    v-on:newClassLive="setClassLive"
                    v-on:newPeriod="setPeriod"
                    v-on:newSession="setSession"
                    v-on:newTier="setTier"
                />
                <v-col cols="12" v-show="currentTab===0">
                    <v-row justify="center">
                        <v-btn
                            class="text-capitalize"
                            color="#FF703C"
                            outlined
                            width="200"
                            @click="$router.push('/teacher/sessions/new')"
                        >
                            {{ $t('sessions.add_new_session') }}
                        </v-btn>
                    </v-row>
                </v-col>
            </v-col>
            <v-col cols="12" lg="9">
                <SessionsTable
                    v-show="currentTab===0"
                    :course="selectedClass"
                    :period="selectedPeriod"
                />
                <SessionsLiveTable
                    v-show="currentTab!==0"
                    :session="selectedSession"
                    :tier="selectedTier"
                />
                <Events section="sessions" />
            </v-col>
        </v-row>
    </v-card>
</template>

<script>

import SessionsTable from '@/components/sessions/SessionsTable.vue'
import SessionsLiveTable from '@/components/sessions/SessionsLiveTable.vue'
import Events from '@/components/stats/Events.vue'
import SelectFilters from '@/components/sessions/SelectFilters.vue'

export default {
    components: {
        SessionsTable,
        SessionsLiveTable,
        Events,
        SelectFilters,
    },
    data() {
        return {
            currentTab: 0,
            selectedClass: null,
            selectedClassLive: null,
            selectedPeriod: 90,
            selectedSession: 0,
            selectedTier: 0,
        }
    },
    computed: {
        items() {
            return [this.$t('sessions.sessions_data_tab'), this.$t('sessions.live_session_tab')]
        },
    },
    methods: {
        changeTab(tab) {
            this.currentTab = tab
        },
        setClass(classParam) {
            this.selectedClass = classParam
        },
        setClassLive(classParam) {
            this.selectedClassLive = classParam
        },
        setPeriod(period) {
            this.selectedPeriod = period
        },
        setSession(session) {
            this.selectedSession = session
        },
        setTier(tier) {
            this.selectedTier = tier
        },
    },
}
</script>
<style scoped>
</style>
