<template>
    <v-card class="mt-4 pt-0 pr-6" color="transparent">
        <v-btn fab x-small dark color="#514773" absolute top right @click="$emit('close')">
            <v-icon>
                mdi mdi-close
            </v-icon>
        </v-btn>
        <v-card
            elevation="0"
            class="pa-0 ma-0"
            height="480"
            width="240"
            color="primary"
            style="color:white;"
        >
            <v-row justify="center" class="pa-3" v-show="!loading">
                <p class="mb-0 mt-2 text-center text-body-1 font-weight-black">
                    {{ sessionDetail.date | date({ date: true, location: $i18n.locale }) }}
                </p>

                <v-row class="mx-2 text-caption border-bottom text-gray" justify="space-around">
                    <v-col cols="3" class="pb-0 px-1">
                        {{ sessionDetail.course }}
                    </v-col>
                    <v-col cols="7" class="pb-0 px-0">
                        {{ sessionDetail.hours }}
                    </v-col>
                </v-row>
                <v-col cols="12">
                    <p class="my-0 py-0 text-body-2 font-weight-black">
                        {{ $t('sessions.avg_reading_time') }}
                    </p>
                    <span class="text-caption text-gray">
                        {{ sessionDetail.readingTime }}
                    </span>
                </v-col>
                <v-col cols="12">
                    <p class="my-0 py-0 text-body-2 font-weight-black">
                        {{ $t('sessions.avg_number_texts_read') }}
                    </p>
                    <span class="text-caption text-gray">
                        {{ sessionDetail.textReads }}
                    </span>
                </v-col>
                <v-col cols="12">
                    <p class="my-0 text-body-2 font-weight-black">
                        {{ $t('sessions.student_attendance_at_session') }}
                    </p>
                    <span class="text-caption text-gray">
                        {{ sessionDetail.studentAttendance }}
                    </span>
                </v-col>
                <v-col cols="12">
                    <p class="my-0 text-body-2 font-weight-black">
                        {{ $t('sessions.compared_performance') }}
                    </p>
                    <span class="text-caption">
                        {{ sessionDetail.performancePercent }}
                    </span>
                    <p class="mb-0 text-caption text-gray">
                        {{ sessionDetail.textPerformance }}
                    </p>
                </v-col>
            </v-row>
            <v-overlay :absolute="true" :value="loading">
                <v-progress-circular
                    indeterminate
                    color="primary"
                    size="100"
                    :width="10"
                />
            </v-overlay>
        </v-card>
    </v-card>
</template>

<script>
import WorkSessionService from '../../services/workSession'

export default {
    props: {
        sessionId: { type: Number, default: 0 },
    },
    data() {
        return {
            sessionDetail: {
                date: '',
                hours: '',
                course: '',
                readingTime: '',
                textReads: '',
                studentAttendance: '',
                performancePercent: '',
                textPerformance: '',
            },
            loading: true,
        }
    },
    mounted() {
        this.loadData()
    },
    methods: {
        formatData(data) {
            this.sessionDetail = {
                date: `${data.year}-${data.month}-${data.day} 00:00:00`,
                hours: `${this.formatHour(data.startingTime)} - ${this.formatHour(data.endingTime)}`,
                course: data.course.name,
                readingTime: this.formatReading(data.readingTime),
                textReads: Math.round(data.textReads),
                studentAttendance: `${data.studentAttendance}%`,
                performancePercent: `${data.performancePercent}%`,
                textPerformance: this.getPerformanceText(data.performancePercent, data.performanceMeasure),
            }
        },
        formatHour(hour) {
            let result = ''
            if (hour < 10) {
                result = `0${hour}:00 am`
            } else {
                result = `${hour}:00 ${hour === 12 ? 'pm' : 'am'}`
            }
            if (hour > 12) {
                result = `0${hour - 12}:00 pm`
            }
            return result
        },
        formatReading(seconds) {
            let hours = 0
            let mins = 0
            let indexH = 1
            let indexM = 1
            while (seconds / (3600 * indexH) > 1) {
                indexH += 1
                hours += 1
            }
            const seconsMenisHours = seconds - (hours * 3600)
            while (seconsMenisHours / (60 * indexM) > 1) {
                indexM += 1
                mins += 1
            }
            const seconsMenisMins = seconsMenisHours - (mins * 60)
            if (seconsMenisMins > 30) {
                mins += 1
            }
            return `${hours} h ${mins} min`
        },
        getPerformanceText(percent, reference) {
            return this.$t(
                'sessions.performance_text',
                {
                    percent,
                    reference: this.$t(`sessions.performance_measure.${reference.toLowerCase()}`),
                },
            )
        },
        loadData() {
            WorkSessionService.getDetailBySession(this.sessionId)
                .then((response) => {
                    this.loading = false
                    this.formatData(response.content)
                })
                .catch((error) => console.error(error))
        },
    },
}
</script>

<style scoped>
.text-gray {
    color: #AFA7CB;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
    border-bottom: 1px dashed gray;
}
.border-bottom {
    border-bottom: 1px solid white;
}
</style>
