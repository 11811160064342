<template>
    <v-card elevation="6" :min-height="420" class="mt-4 mb-12 px-4 mr-6">
        <v-data-table
            :headers="headers"
            :items="sessions"
            :loading="loading"
            hide-default-footer
            hide-default-header
        >
            <template v-slot:header="{ props: { headers } }">
                <thead>
                    <tr>
                        <th colspan="7" scope="row">
                            <v-row class="text-caption text-xl-body-2 font-weight-black">
                                <v-col cols="3" class="py-4">{{ headers[0].text }}</v-col>
                                <v-col cols="1" class="py-4">{{ headers[1].text }}</v-col>
                                <v-col cols="1" class="py-4">{{ headers[2].text }}</v-col>
                                <v-col cols="4" class="py-4">{{ headers[3].text }}</v-col>
                                <v-col cols="1" class="py-3 px-0">{{ headers[4].text }}</v-col>
                                <v-col cols="1" class="py-3 px-0">{{ headers[5].text }}</v-col>
                                <v-col cols="1" class="py-3 px-0">{{ headers[6].text }}</v-col>
                            </v-row>
                        </th>
                    </tr>
                </thead>
            </template>
            <template v-slot:body="{ items }">
                <tbody class="text-body-2 font-weight-bold text-gray">
                    <tr
                        v-for="item in items"
                        :key="item.challengeAssignmentId"
                    >
                        <v-menu
                            v-model="item.show"
                            :close-on-click="true"
                            :close-on-content-click="false"
                            absolute
                            offset-y
                        >
                            <template v-slot:activator="{ on }">
                                <v-row class="mx-0" v-on="on">
                                    <v-col cols="3">
                                        <v-row align="center">
                                            <v-col cols="2" class="py-0">
                                                <v-img
                                                    :src="require('../../assets/img/icons/library-enddate-icon.svg')"
                                                    contain
                                                    max-height="20"
                                                />
                                            </v-col>
                                            <v-col cols="10" class="py-0">
                                                {{ item.dateTimeExecution | date({ date: true, location: $i18n.locale }) }}
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="1">{{ item.correct }}</v-col>
                                    <v-col cols="1">{{ item.total }}</v-col>
                                    <v-col cols="4" class="py-1">
                                        <BeeProgressBar
                                            :color="getColorAccuracyBar(item.accuracy)"
                                            class="pa-0 text-center font-size-14"
                                            :progress="Math.round(item.accuracy)"
                                            :backgroundless="true"
                                            :valueColorBlack="textBlack(item.accuracy)"
                                        >
                                            {{ `${ Math.round(item.accuracy) }%` }}
                                        </BeeProgressBar>
                                    </v-col>
                                    <v-col cols="1" class="px-0">{{ Math.round(item.locAccuracy) }}%</v-col>
                                    <v-col cols="1" class="px-0">{{ Math.round(item.inAccuracy) }}%</v-col>
                                    <v-col cols="1" class="px-0">{{ Math.round(item.rvAccuracy) }}%</v-col>
                                </v-row>
                            </template>
                            <SessionsTableDetail :sessionId="item.challengeAssignmentId" @close="closeDetail()" />
                        </v-menu>
                    </tr>
                    <tr v-show="items.length===0">
                        <th colspan="7" scope="row">
                            <v-row justify="center">
                                {{ $t('sessions.no_data') }}
                            </v-row>
                        </th>
                    </tr>
                </tbody>
            </template>
        </v-data-table>
        <v-overlay :absolute="true" :value="sessions.length===0 && loading">
            <v-progress-circular
                indeterminate
                color="primary"
                size="100"
                :width="10"
            />
        </v-overlay>
    </v-card>
</template>

<script>
import SessionsTableDetail from './SessionsTableDetail'
import WorkSessionService from '../../services/workSession'

export default {
    props: {
        course: { type: Number, default: 0 },
        period: { type: Number, default: 0 },
    },
    components: {
        SessionsTableDetail,
    },
    data() {
        return {
            showDescription: false,
            loading: false,
            sessions: [],
        }
    },
    computed: {
        headers() {
            return [
                { text: this.$t('sessions.sessions_dates'), align: 'start', value: 'dateTimeExecution', sortable: false, },
                { text: this.$t('sessions.correct'), value: 'correct', sortable: false, },
                { text: 'Total', value: 'total', sortable: false, },
                { text: this.$t('sessions.accuracy'), value: 'accuracy', sortable: false, },
                { text: this.$t('sessions.find_locate'), value: 'locAccuracy', sortable: false, },
                { text: this.$t('sessions.integrate_interpret'), value: 'inAccuracy', sortable: false, },
                { text: this.$t('sessions.reflect_valuate'), value: 'rvAccuracy', sortable: false, },
            ]
        },
    },
    mounted() {
        this.loading = true
        this.loadData()
    },
    methods: {
        closeDetail() {
            this.sessions = this.sessions.map((item) => {
                const resp = item
                resp.show = false
                return resp
            })
        },
        getColorAccuracyBar(progress) {
            let result = '#6F55D0'
            if (progress >= 40) {
                result = '#FDDC61'
            }
            if (progress >= 67) {
                result = '#34DBAA'
            }
            return result
        },
        mapData(items) {
            this.sessions = items.map((item) => ({ show: false, ...item }))
        },
        loadData() {
            if (!this.course || !this.period) {
                return
            }
            WorkSessionService.getWorkSessionStatsByCourseId(this.course, this.period, 'WORK_GROUP')
                .then((response) => {
                    this.loading = false
                    this.mapData(response.content)
                })
                .catch((error) => console.error(error))
        },
        textBlack(progress) {
            return progress < 20 || (progress >= 40 && progress < 67)
        },
    },
    watch: {
        course() {
            this.loading = true
            this.loadData()
        },
        period() {
            this.loading = true
            this.loadData()
        },
    },
}
</script>
<style scoped>
.text-gray {
    color: #8D8D8D;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
    border-bottom: 1px dashed gray;
}
.border-bottom {
    border-bottom: 1px solid white;
}
.v-menu__content {
    box-shadow: none;
}
</style>
