<template>
    <v-card class="mt-4 pt-0 pr-6" color="transparent">
        <v-btn fab x-small dark color="#514773" absolute top right @click="$emit('close')">
            <v-icon>
                mdi mdi-close
            </v-icon>
        </v-btn>
        <v-card
            elevation="0"
            class="pa-0 ma-0"
            height="400"
            width="290"
            color="primary"
            style="color:white;"
        >
            <v-row justify="center">
                <v-img
                    :src="studentDetail.avatar"
                    max-height="60"
                    max-width="60"
                />
            </v-row>
            <v-row justify="center" class="pa-3" v-show="!loading">
                <v-col cols="12" class="py-0">
                    <v-row justify="center">
                        <p class="mb-0 mt-2 px-12 text-center text-body-1 font-weight-black">
                            {{ studentDetail.name }}
                        </p>
                    </v-row>
                </v-col>
                <v-col cols="12" class="py-0">
                    <v-row class="mx-2 text-caption border-bottom text-gray" justify="space-around">
                        <v-col cols="4" class="pb-0 px-1">
                            {{ studentDetail.course }}
                        </v-col>
                        <v-col cols="4" class="pb-0 px-1">
                            {{ studentDetail.userLevel }}
                        </v-col>
                    </v-row>
                </v-col>
                <v-row class="mx-0">
                    <v-col cols="6">
                        <p class="my-0 py-0 text-body-2 font-weight-black">
                            {{ $t('sessions.reading_time') }}
                        </p>
                        <span class="text-caption text-gray">
                            {{ studentDetail.readingTime }}
                        </span>
                    </v-col>
                    <v-col cols="6">
                        <p class="my-0 py-0 text-body-2 font-weight-black">
                            {{ $t('sessions.texts_read') }}
                        </p>
                        <span class="text-caption text-gray">
                            {{ studentDetail.textReads }}
                        </span>
                    </v-col>
                </v-row>
                <v-col cols="12">
                    <p class="my-0 text-body-2 font-weight-black">
                        {{ $t('sessions.currently_reading') }}
                    </p>
                    <span class="text-caption text-gray">
                        {{ studentDetail.currentlyReading }}
                    </span>
                </v-col>
                <v-col cols="12">
                    <p class="my-0 text-body-2 font-weight-black">
                        {{ $t('sessions.compared_performance') }}
                    </p>
                    <p class="mb-0 text-caption text-gray">
                        {{ studentDetail.textPerformance }}
                    </p>
                </v-col>
            </v-row>
            <v-overlay :absolute="true" :value="loading">
                <v-progress-circular
                    indeterminate
                    color="primary"
                    size="100"
                    :width="10"
                />
            </v-overlay>
        </v-card>
    </v-card>
</template>

<script>
import WorkSessionService from '../../services/workSession'

export default {
    props: {
        sessionId: { type: Number, default: 0 },
        studentId: { type: Number, default: 0 },
    },
    data() {
        return {
            studentDetail: {
                avatar: '',
                name: '',
                course: '',
                userLevel: '',
                readingTime: '',
                currentlyReading: '',
                textReads: '',
                textPerformance: '',
            },
            loading: true,
        }
    },
    mounted() {
        this.loadData()
    },
    methods: {
        formatData(data) {
            this.studentDetail = {
                avatar: `${process.env.VUE_APP_S3RESOURCES}/avatar/${data.user.avatar.name}/${data.user.avatar.value}`,
                name: `${this.toCapitalize(data.user.firstname)} ${this.toCapitalize(data.user.lastname)}`,
                course: data.course.name,
                userLevel: `${this.$t('sessions.level')} ${data.userLevel}`,
                readingTime: this.formatReading(data.readingTime),
                currentlyReading: data.currentlyReading,
                textReads: Math.round(data.textReads),
                textPerformance: this.getPerformanceText(
                    this.toCapitalize(data.user.firstname),
                    data.performancePercent,
                    data.performanceMeasure,
                    data.user.genre,
                ),
            }
        },
        toCapitalize(text) {
            return this.$options.filters.capitalize(text)
        },
        formatReading(seconds) {
            let hours = 0
            let mins = 0
            let indexH = 1
            let indexM = 1
            while (seconds / (3600 * indexH) > 1) {
                indexH += 1
                hours += 1
            }
            const seconsMenisHours = seconds - (hours * 3600)
            while (seconsMenisHours / (60 * indexM) > 1) {
                indexM += 1
                mins += 1
            }
            const seconsMenisMins = seconsMenisHours - (mins * 60)
            if (seconsMenisMins > 30) {
                mins += 1
            }
            return `${hours} h ${mins} min`
        },
        getPerformanceText(name, percent, reference, genre) {
            return this.$t(
                'sessions.performance_text_student',
                {
                    name,
                    percent,
                    reference: this.$t(`sessions.performance_measure_student.${reference.toLowerCase()}`),
                    genre: this.$t(`sessions.performance_genre.${genre.toLowerCase()}`),
                },
            )
        },
        loadData() {
            WorkSessionService.getDetailBySessionAndStudent(this.sessionId, this.studentId)
                .then((response) => {
                    this.loading = false
                    this.formatData(response.content)
                })
                .catch((error) => console.error(error))
        },
    },
}
</script>

<style scoped>
.text-gray {
    color: #AFA7CB;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
    border-bottom: 1px dashed gray;
}
.border-bottom {
    border-bottom: 1px solid white;
}
</style>
